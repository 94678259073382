import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fontStyles } from '../constants/styles';


const Wrapper = styled.h1`
  ${fontStyles.title}
  margin: 0 0 4.5rem 0; /*72/16*/
  text-align: center;
`;


const PageTitle = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitle;
