import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { fontStyles } from '../constants/styles';
import { customMarkdown } from '../helpers/markdown';

import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Jobs from '../components/Jobs';
import Layout from '../components/Layout';
import ModuleWrapper from '../components/ModuleWrapper';
import PageTitle from '../components/PageTitle';


const Wrapper = styled(ModuleWrapper)`
  ${fontStyles.body};
`;

const JobsPage = ({ data, location, pageContext }) => {
  const { languageSwitch } = pageContext;
  const footerData = data.allDatoCmsFooter.edges[0].node;
  const jobsPage = data.allDatoCmsJobsPage.edges[0].node;
  const { description, gradient, name, seoMetaTags } = jobsPage;

  return (
    <Layout languageSwitch={languageSwitch} location={location}>
      <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
        <HelmetMichelberger seo={seoMetaTags} />
        <PageTitle>{name}</PageTitle>
        <Wrapper>{customMarkdown(description)}</Wrapper>
        <Jobs />
      </GradientWrapper>
    </Layout>
  );
};

JobsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};


export default JobsPage;

export const query = graphql`
  query JobsPage($locale: String!) {
    allDatoCmsJobsPage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
          name
          description
          gradient {
            color {
              hex
            }
          }
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
  }
`;
