import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../constants/mediaqueries';
import { SPACING_VERTICAL_HEADINGS } from '../constants/spacings';
import { fontStyles } from '../constants/styles';


const Wrapper = styled.h2`
  ${SPACING_VERTICAL_HEADINGS.s};
  ${fontStyles.subtitle}
  text-align: center;

  @media ${breakpoints.l} {
    ${SPACING_VERTICAL_HEADINGS.l};
  }
`;

const SubTitle = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubTitle;
