import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../constants/mediaqueries';
import { COLOR_WHITE } from '../constants/colors';
import { SPACING_SIDE_MOBILE } from '../constants/spacings';
import { getNavOpen, getWindowSize } from '../state/reducers';
import { windowSizeTypes } from '../types';

import Footer from './Footer';


const createGradient = (colors) => {
  if (!colors || colors.length === 0) {
    return COLOR_WHITE;
  } if (colors.length === 1) {
    return colors[0].color.hex;
  }

  const gradient = colors.map((c) => c.color.hex).join(',');

  return `linear-gradient(${gradient})`;
};

const OuterWrapper = styled.div`
  position: relative;
  height: ${(props) => (props.open ? '100vh' : 'auto')}
  overflow-y: ${(props) => (props.open ? 'hidden' : 'auto')}
`;

const ColorWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => createGradient(props.gradient)};
  padding-top: 5rem; /* 80/16 */
`;

const OuterCol = styled.main`
  padding-top: ${(props) => (props.isBookingPage ? '5rem' : '7.5rem')}; /* 120/16 */
  position: relative;
  opacity: ${(props) => (props.open ? 0 : 1)};
  transition: opacity .3s linear;
  transition-delay: ${(props) => (props.open ? 0 : '.3s')};

  @media ${breakpoints.l} {
    opacity: 1;
  }
`;

const InnerCol = styled.div`
  width: calc(100% - ${SPACING_SIDE_MOBILE}); /* 40/16 */
  margin: 0 auto;

  @media ${breakpoints.l} {
    width: calc(100% - 30vw);
    max-width: calc(100% - 25rem); /* 400/16 */
  }
`;

const GradientWrapper = ({ children, footerData, gradient, isBookingPage = false, languageSwitch, open, windowSize }) => (
  <OuterWrapper open={open}>
    <ColorWrapper className="gradient" gradient={gradient} />
    <OuterCol className="content" isBookingPage={isBookingPage} open={open}>
      <InnerCol>
        {children}
        <If condition={footerData}>
          <Footer footerData={footerData} languageSwitch={languageSwitch} legalOnly={isBookingPage} windowSize={windowSize} />
        </If>
      </InnerCol>
    </OuterCol>
  </OuterWrapper>
);

GradientWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  footerData: PropTypes.object,
  gradient: PropTypes.array,
  isBookingPage: PropTypes.bool,
  languageSwitch: PropTypes.array,
  open: PropTypes.bool,
  windowSize: windowSizeTypes.isRequired,
};

GradientWrapper.defaultProps = {
  gradient: [],
  languageSwitch: [],
};

const mapStateToProps = (state) => ({
  open: getNavOpen(state),
  windowSize: getWindowSize(state),
});

export default connect(mapStateToProps)(GradientWrapper);
