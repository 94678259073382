import styled from 'styled-components';

import { SPACING_VERTICAL } from '../constants/spacings';
import { breakpoints } from '../constants/mediaqueries';


export default styled.section`
  {
    ${SPACING_VERTICAL.s};

    @media ${breakpoints.l} {
      ${({ isSmall }) => (!isSmall && SPACING_VERTICAL.l)};
    }
  }
`;
