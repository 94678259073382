import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COOKIE_BANNER_ID } from '../constants/cookies';
import { showDialog } from '../helpers/dialog';
import { underlinedButtonStyles } from '../constants/styles';


const StyledButton = styled.button`
  ${underlinedButtonStyles}
  font-size: inherit;
`;

function CookieDialogTrigger({ label }) {
  const handleClick = () => {
    const dialog = document.getElementById(COOKIE_BANNER_ID);

    if (dialog) {
      showDialog(dialog);
    }
  };

  return (
    <StyledButton
      onClick={handleClick}
      type="button"
    >
      {label}
    </StyledButton>
  );
}

CookieDialogTrigger.propTypes = {
  label: PropTypes.string,
};

export default CookieDialogTrigger;
