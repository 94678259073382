import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ExternalLink from './ExternalLink';
import { Row, RowWrapper } from './Row';


const JobTitle = styled.span`
  margin-right: auto;
`;

const Job = ({ job }) => (
  <RowWrapper>
    <ExternalLink hasUnderline={false} href={job.showUrl}>
      <Row>
        <JobTitle>{job.title}</JobTitle>
        <FormattedMessage defaultMessage="Apply Here" id="global.applyHere" />
      </Row>
    </ExternalLink>
  </RowWrapper>
);

Job.propTypes = {
  job: PropTypes.object.isRequired,
};

export default Job;
