import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import Job from './Job';
import ModuleWrapper from './ModuleWrapper';
import SubTitle from './SubTitle';


class Jobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobs: this.props.jobs,
    };
  }

  render() {
    const { jobs } = this.state;
    if (!jobs.length) return null;

    return (
      <ModuleWrapper>
        <SubTitle>
          <FormattedMessage defaultMessage="Available Positions" id="global.availablePositions" />
        </SubTitle>
        <For each="j" of={jobs}>
          <Job key={j.shortHandle} job={j} />
        </For>
      </ModuleWrapper>
    );
  }
}

Jobs.propTypes = {
  jobs: PropTypes.array,
};

Jobs.defaultProps = {
  jobs: [],
};

export default injectIntl(Jobs);
