import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider, FormattedMessage } from 'react-intl';

import { COLOR_BLACK } from '../constants/colors';
import { getMessages, getSingletonForLang } from '../helpers/i18n';
import { breakpoints } from '../constants/mediaqueries';
import { fontStyles, linkStyles } from '../constants/styles';
import { customMarkdown } from '../helpers/markdown';
import { isTinyViewport } from '../helpers/window';
import { windowSizeTypes } from '../types';

import LocalisedLink, { UnderlinedLink } from './LocalisedLink';
import CookieDialogTrigger from './CookieDialogTrigger';


const Wrapper = styled.footer`
  position: relative;
  margin-top: 10rem;
  padding-bottom: 1.875rem;
  clear: both; /* for floated booking page layout */

  h1, h2 {
    ${fontStyles.uppercase}
    margin-bottom: -1.5rem;

    @media ${breakpoints.l} {
      margin-bottom: 1.25rem;
    }
  }

  p {
    ${fontStyles.footer}
    margin: 2rem 0 2rem;

    @media ${breakpoints.l} {
      margin: 2rem 0;
    }

    a {
      ${linkStyles}
    }
  }
`;

const Flex = styled.div`
  @media ${breakpoints.l} {
    display: flex;
    justify-content: space-between;
  }
`;

const Column = styled.div`
  p {
    margin: .5rem 0;
  }

  h1, h2 {
    margin-top: 2rem;
    margin-bottom: 0;

    @media ${breakpoints.l} {
      margin-bottom: 1.25rem; /* 20/16 */
    }
  }

  @media ${breakpoints.l} {
    width: calc(50% - 4vw);

    p {
      margin: 2rem 0 2rem;
    }
  }
`;

const Legal = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 2rem;
  ${fontStyles.footer}

  @media ${breakpoints.l} {
    flex-direction: row;
    align-items: flex-end;
    margin-top: 3.125rem; /* 50/16 */

    li {
      display: inline-block;
    }

    li + li {
      margin-left: 2rem;
    }
  }
`;

const Languages = styled.ul`
  position: absolute;
  right: 0;
  bottom: 1.875rem;

  li {
    display: inline-block;
  }

  li + li {
    margin-left: 1rem;
  }

  a {
    text-decoration: none;
    color: ${COLOR_BLACK};
  }

  @media ${breakpoints.l} {
    position: static;
    margin: 0 0 0 auto;

    li + li {
      margin-left: 2rem;
    }
  }
`;

const Copyright = styled.span`
  display: block;
  margin-top: 1.5em;

  @media ${breakpoints.xl} {
    display: inline-block;
  }
`;

const Credit = styled.span`
  display: block;

  @media ${breakpoints.l} {
    display: inline-block;

    &::before {
      margin-left: .5em;
      margin-right: .5em;
    }

    & + &::before {
      content: '•';
    }
  }

  @media ${breakpoints.xl} {
    &::before {
      content: '•';
    }
  }
`;

const Footer = ({ footerData, languageSwitch, legalOnly = false, windowSize }) => (
  <StaticQuery
    query={graphql`
        {
          allDatoCmsGlobalLabeling {
            edges {
              node {
                locale
                changeLanguage
              }
            }
          }
        }
      `}
    render={(data) => (
      <Wrapper>
        <If condition={!legalOnly}>
          <Flex>
            <Column>{customMarkdown(footerData.bookings)}</Column>
            <Column>{customMarkdown(footerData.newsletterSocialDescription)}</Column>
          </Flex>
        </If>
        <Legal>
          <div>
            <ul>
              <For each="legal" of={footerData.legalLinks}>
                <li key={legal.slug}>
                  <LocalisedLink to={`/${legal.slug}`}>
                    {legal.title}
                  </LocalisedLink>
                </li>
              </For>
              <li><CookieDialogTrigger label={footerData.cookieConsentLinkLabel} /></li>
            </ul>
            <Copyright>&copy; {footerData.copyrightText}</Copyright>
            <Credit>{footerData.photographyCopyrightText}</Credit>
            <Credit>{footerData.websiteCredit}</Credit>
          </div>
          <If condition={languageSwitch}>
            <Languages>
              <For each="lang" of={languageSwitch}>
                <With
                  langLink={(<UnderlinedLink to={lang.link}>{lang.langName}</UnderlinedLink>)}
                  messages={getMessages({ global: getSingletonForLang(data.allDatoCmsGlobalLabeling, lang.langKey) })}
                >
                  <IntlProvider key={lang.langKey} locale={lang.langKey} messages={messages}>
                    <li key={lang.langKey}>
                      <Choose>
                        <When condition={isTinyViewport(windowSize)}>
                          {langLink}
                        </When>
                        <Otherwise>
                          <FormattedMessage
                            id="global.changeLanguage"
                            values={{ langLink }}
                          />
                        </Otherwise>
                      </Choose>
                    </li>
                  </IntlProvider>
                </With>
              </For>
            </Languages>
          </If>
        </Legal>
      </Wrapper>
    )}
  />
);

Footer.propTypes = {
  footerData: PropTypes.shape({
    bookings: PropTypes.string,
    copyrightText: PropTypes.string.isRequired,
    photographyCopyrightText: PropTypes.string.isRequired,
    websiteCredit: PropTypes.string,
    legalLinks: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })).isRequired,
    newsletterSocialDescription: PropTypes.string,
    cookieConsentLinkLabel: PropTypes.string,
  }).isRequired,
  languageSwitch: PropTypes.arrayOf(PropTypes.shape({
    langKey: PropTypes.string.isRequired,
    langName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })),
  legalOnly: PropTypes.bool,
  windowSize: windowSizeTypes.isRequired,
};

export default Footer;
