import styled from 'styled-components';

import { COLOR_BLACK } from '../constants/colors';
import { fontStyles } from '../constants/styles';
import { breakpoints } from '../constants/mediaqueries';


export const RowWrapper = styled.div`
  border-top: 1px solid ${COLOR_BLACK};
  transition: transform .2s;

  &:last-child {
    border-bottom: 1px solid black;
  }

  > a {
    display: block;
    text-decoration: none;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .5em 0;
  ${fontStyles.body}
  @media ${breakpoints.l} {
    padding: .9em 0;
  }
`;
